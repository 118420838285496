import {React,useState, useEffect } from 'react';

export default function Contacts() {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка страницы в начало при монтировании компонента
  }, []);
  
  const [isHovered, setIsHovered] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      if (showModal) {
          setLoading(true);
          setTimeout(() => {
              setLoading(false);
          }, 1200);
      }
  }, [showModal]);
  
  return (
    <section className="contacts container">
      <div className="contacts-block flex">
        <div className="contacts-info flex">
          <h2 className="contacts-info-title">
            КОНТАКТЫ
          </h2>
          <span>
            <i style={{color: "#1D4ED8"}}>ООО «МедГуру»</i>
          </span>

          {/* <div className="description-btn-sec flex"
            style={{justifyContent: "flex-start"}}
          >
            <button 
              className="description-btn btn-reset flex" 
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => setShowModal(true)} 

              style={{padding: "20px 20px", borderRadius: "20px"}}
            >
              <span style={{ display: "flex", alignItems: "center", fontSize: "28px", textTransform: "none" }}>Узнать больше</span>
              <svg 
                width="27" 
                height="28" 
                className={isHovered ? "arrow active" : "arrow"} 
                viewBox="0 0 27 28" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 1.03455L25.5 26.0303M25.5 26.0303V0.969604M25.5 26.0303H0.5" stroke="white" strokeWidth="2"/>
              </svg>
            </button>
        </div> */}

        {/* {showModal && (
            <div className="modal-overlay" onClick={() => setShowModal(false)}>
                <div className="modal-content flex" onClick={(e) => e.stopPropagation()}>
                    <button className="close-btn" onClick={() => setShowModal(false)}>×</button>

                    <h2 className="modal-title">Узнайте больше о наших возможностях</h2>

                    <div className="iframe-container">
                        {loading && (
                            <div className="loader-container">
                                <div className="spinner"></div>
                            </div>
                        )}
                        <iframe 
                            src="https://b24-d571t0.bitrix24site.ru/" 
                            width="100%" 
                            height="100%" 
                            style={{ border: "none", opacity: loading ? 0 : 1, transition: "opacity 0.5s" }}
                        ></iframe>
                    </div>
                </div>
            </div>
        )} */}

          <div className="contacts-info-block">
            <h3 className="contacts-info-subtitle">
              Адрес
            </h3>
            <p className="contacts-info-adress">
              123060, г. Москва, ул. Маршала Рыбалко, дом 2, корпус 6, помещение I, комната 5, офис 722
            </p>
          </div>

          <div className="contacts-info-block">
            <h3 className="contacts-info-subtitle">
              Телефон/почта
            </h3>
            <ul className="item-list flex list-reset" style={{paddingTop: "10px"}}>
                <li className="item-points">
                    Общий номер телефона<br/>
                    <a href="tel:+74954454510">
                      +7 495 445-45-10 
                    </a>
                </li>
                <li className="item-points">
                    Ведущий менеджер по развитию бизнеса<br/>
                    <a href="tel:+79995006454">
                      +7 929 662 35 61
                    </a>
                </li>
                <li className="item-points">
                    Руководитель ИТ-отдела<br/>
                    <a href="tel:+79998699276">
                        +7 999 869-92-76  
                    </a>
                </li>
                <li className="item-points">
                    <a href="mailto:mail@medguru.studio">
                        mail@medguru.studio
                    </a>
                </li>
            </ul>
          </div>
        </div>

        <div className="map" style={{display: "flex", alignItems: "center"}}>
          <iframe className="y-maps" title="карта" src="https://yandex.ru/map-widget/v1/?um=constructor%3Accce73efb95cd3d994f5b23018a3038c68f6b0639fbff59a6ba533d26b598fee&amp;source=constructor" width="832" height="502" frameBorder="0" style={{borderRadius:"50px"}}></iframe>
        </div> 
      </div>
    </section>
  )
}
