import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import bck from "../../img/First.png";

export default function Description({ scrollToElement }) {
    const [isHovered, setIsHovered] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate()

    useEffect(() => {
        if (showModal) {
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 1200);
        }
    }, [showModal]);

    const knowMore = () => {
        navigate("/contacts")
    }

    return (
        <div>
            <div 
                className="description-section flex" 
                style={{ 
                    backgroundImage: `linear-gradient(to bottom, black 0%, transparent 100%), url(${bck})`, 
                    backgroundRepeat: "no-repeat", 
                    backgroundSize: "cover", 
                    backgroundPosition: "center" 
                }}
            >
                <div className="description container flex">
                    <ul className="description-list list-reset">
                        <li className="description-item first">прогрессивное</li>
                        <li className="description-item second">digital-агентство,</li>
                        <li className="description-item third">специализирующееся</li>
                        <li className="description-item fourth">на создании</li>
                        <li className="description-item fifth">облачной архитектуры</li>
                        <li className="description-item sixth">для фармбизнеса</li>
                    </ul>

                    <div className="description-btn-sec flex">
                        <button 
                            className="description-btn btn-reset flex" 
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            // onClick={() => setShowModal(true)} 
                            onClick={() => knowMore()}
                        >
                            <span style={{ display: "flex", alignItems: "center" }}>узнать больше</span>
                            <svg 
                                width="27" 
                                height="28" 
                                className={isHovered ? "arrow active" : "arrow"} 
                                viewBox="0 0 27 28" 
                                fill="none" 
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M1 1.03455L25.5 26.0303M25.5 26.0303V0.969604M25.5 26.0303H0.5" stroke="white" strokeWidth="2"/>
                            </svg>
                        </button>
                    </div>
                </div> 
            </div>

            {/* {showModal && (
                <div className="modal-overlay" onClick={() => setShowModal(false)}>
                    <div className="modal-content flex" onClick={(e) => e.stopPropagation()}>
                        <button className="close-btn" onClick={() => setShowModal(false)}>×</button>

                        <h2 className="modal-title">Узнайте больше о наших возможностях</h2>

                        <div className="iframe-container">
                            {loading && (
                                <div className="loader-container">
                                    <div className="spinner"></div>
                                </div>
                            )}
                            <iframe 
                                src="https://b24-d571t0.bitrix24site.ru/" 
                                width="100%" 
                                height="100%" 
                                style={{ border: "none", opacity: loading ? 0 : 1, transition: "opacity 0.5s" }}
                            ></iframe>
                        </div>
                    </div>
                </div>
            )} */}

            <div id="1" className="description-add container">
                <i style={{ color: "#1D4ED8" }}>MedGuru</i>&nbsp;&mdash; полноценная двусторонняя коммуникация.
                Наша цель&nbsp;&mdash; помочь клиентам оптимизировать процессы и&nbsp;улучшить их&nbsp;маркетинговую стратегию, чтобы достичь <i style={{ color: "#1D4ED8" }}>максимальных показателей.</i>
            </div>
        </div>
    );
}
