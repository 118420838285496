export const dynamicRoutes = {
    omnichannel: [
        { 
            id: "1", 
            title: "Педиатры 1", 
            img: "https://i.postimg.cc/JngV2jq4/2025-03-21-17-41-52.png",
            link: "https://health-academy.bayer.ru/wp-content/uploads/2025/03/pamyatka-dlya-paczientov-s-allergicheskim-rinitom.pdf?utm_source=medguru&utm_medium=other&utm_content=email_rinit_ped_1&utm_campaign=phd_bayer_2025_aerius_afrin&invited=yes"
        },

        { 
            id: "2", 
            title: "Педиатры 2", 
            img: "https://i.postimg.cc/JngV2jq4/2025-03-21-17-41-52.png",
            link: "https://health-academy.bayer.ru/wp-content/uploads/2025/03/pamyatka-dlya-paczientov-s-krapivniczej.pdf?utm_source=medguru&utm_medium=other&utm_content=email_urticaria_ped_1&utm_campaign=phd_bayer_2025_aerius_afrin&invited=yes"
        },

        { 
            id: "3", 
            title: "Педиатры 3", 
            img: "https://i.postimg.cc/JngV2jq4/2025-03-21-17-41-52.png",
            link: "https://health-academy.bayer.ru/products/erius-rastvor/?utm_source=medguru&utm_medium=other&utm_content=email_ped_1&utm_campaign=phd_bayer_2025_aerius_afrin&invited=yes"
        },

        { 
            id: "4", 
            title: "ЛОР 1", 
            img: "https://i.postimg.cc/JngV2jq4/2025-03-21-17-41-52.png",
            link: "https://health-academy.bayer.ru/wp-content/uploads/2025/03/pamyatka-dlya-paczientov-s-allergicheskim-rinitom.pdf?utm_source=medguru&utm_medium=other&utm_content=email_rinit_otolar_1&utm_campaign=phd_bayer_2025_aerius_afrin&invited=yes"
        },

        { 
            id: "5", 
            title: "ЛОР 2", 
            img: "https://i.postimg.cc/JngV2jq4/2025-03-21-17-41-52.png",
            link: "https://health-academy.bayer.ru/stati/progressirovanie-pollinoza-k-chemu-gotovitsya-i-chem-lechit/?utm_source=medguru&utm_medium=other&utm_content=email_otolar_1&utm_campaign=phd_bayer_2025_aerius_afrin&invited=yes"
        },

        { 
            id: "6", 
            title: "Дерма 1", 
            img: "https://i.postimg.cc/JngV2jq4/2025-03-21-17-41-52.png",
            link: "https://health-academy.bayer.ru/wp-content/uploads/2025/03/pamyatka-dlya-paczientov-s-krapivniczej.pdf?utm_source=medguru&utm_medium=other&utm_content=email_urticaria_derma_1&utm_campaign=phd_bayer_2025_aerius_afrin&invited=yes"
        },

        { 
            id: "7", 
            title: "Дерма 2", 
            img: "https://i.postimg.cc/JngV2jq4/2025-03-21-17-41-52.png",
            link: "https://health-academy.bayer.ru/stati/chto-skryvaet-zud/?utm_source=medguru&utm_medium=other&utm_content=email_derma_1&utm_campaign=phd_bayer_2025_aerius_afrin&invited=yes"
        },

        { 
            id: "8", 
            title: "Аллерго 1", 
            img: "https://i.postimg.cc/JngV2jq4/2025-03-21-17-41-52.png",
            link: "https://health-academy.bayer.ru/wp-content/uploads/2025/03/pamyatka-dlya-paczientov-s-allergicheskim-rinitom.pdf?utm_source=medguru&utm_medium=other&utm_content=email_rinit_allerg_1&utm_campaign=phd_bayer_2025_aerius_afrin&invited=yes"
        },

        { 
            id: "9", 
            title: "Аллерго 2", 
            img: "https://i.postimg.cc/JngV2jq4/2025-03-21-17-41-52.png",
            link: "https://health-academy.bayer.ru/wp-content/uploads/2025/03/pamyatka-dlya-paczientov-s-krapivniczej.pdf?utm_source=medguru&utm_medium=other&utm_content=email_urticaria_allerg_1&utm_campaign=phd_bayer_2025_aerius_afrin&invited=yes"
        },

        { 
            id: "10", 
            title: "Аллерго 3", 
            img: "https://i.postimg.cc/JngV2jq4/2025-03-21-17-41-52.png",
            link: "https://health-academy.bayer.ru/stati/progressirovanie-pollinoza-k-chemu-gotovitsya-i-chem-lechit/?utm_source=medguru&utm_medium=other&utm_content=email_allerg_1&utm_campaign=phd_bayer_2025_aerius_afrin&invited=yes"
        },
    ],
  };