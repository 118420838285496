import Yimg from "../../img/Video Yaroslav.png";
import Aimg from "../../img/Video Alexandr.png";


export const videoData = [
    {
        id: 1,
        title: "Системный подход в бизнес-среде",
        text1: "Многие компании вкладывают средства в различные маркетинговые каналы, но при этом проблематично оценить комплексное влияние на тот или иной сегмент целевой аудитории.",
        text2: "Команда MEDGURU разрабатывает экосистемные продукты, позволяющие фармацевтическому бизнесу отслеживать эффективность маркетинговой стратегии, понимать предпочтения врачей в зависимости от контента, корректировать digital-активности так, чтобы обеспечить оптимальный возврат инвестиций ROI.",
        name: "Ярослав Третьяков",
        position: "основатель группы компаний",
        videoImg: Yimg,
        videoLink: "https://facecast.net/w/pmsf4l",
        alt: "Видео об экосистеме Третьяков Я.О.",
    },

    {
        id: 2,
        title: "ОБЪЕДИНЯЙТЕ ВРАЧЕЙ ВОКРУГ ВАШИХ БРЕНДОВ",
        text1: "В базе программы для ЭВМ 'Образовательная медицинская платформа Medtouch 2.0' (внесена в Реестр отечественного ПО, реестровая запись № 26252 от 27.01.2025 г.) более 200 тыс. врачей разных специальностей, каждый из них имеет свою уникальную карту потребления контента. Что-то интересует больше, что-то меньше.",
        text2: "Благодаря накопленной истории каждого специалиста мы гибко сегментируем целевую аудиторию для наших партнеров на основе интересов врачей и их поведения. Быть актуальными и полезными для них — наш главный приоритет.",
        name: "Александр Селюков",
        position: "ведущий менеджер по маркетингу",
        videoImg: Aimg,
        videoLink: "https://facecast.net/w/p89mhy",
        alt: "Видео об омниканальных проектах Селюков А.Р.",
    }
]