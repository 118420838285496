import { React } from 'react';
import { Link,  useNavigate } from "react-router-dom";

export default function Footer({ currentPath, scrollToElement }) {
    const navigate = useNavigate();
    
    const handleClick = (id) => {
        if (currentPath !== '/') {
          // Если мы не на главной странице, переходим на главную
          navigate('/');
        }
        // Прокручиваем к нужному элементу
        scrollToElement(id);
      };
    
    return (
        <section className="footer-section flex">
            <div className="footer-container container">
                <ul className="footer-list list-reset flex">
                    <li className="footer-list-item">
                        <h3 className="item-title">
                            Экосистема Медгуру
                        </h3>
                        <ul className="item-list flex list-reset">
                            <li className="item-points">
                                <a href="#1" onClick={() => handleClick('1')} style={{cursor: "pointer"}}>
                                    MedGuru — полноценная двусторонняя 
                                    коммуникация
                                </a>
                            </li>
                            <li className="item-points">
                                <a href="#2" onClick={() => handleClick('2')} style={{cursor: "pointer"}}>
                                    Области нашей экпертизы
                                </a>
                            </li>
                            <li className="item-points">
                                <a href="#3" onClick={() => handleClick('3')} style={{cursor: "pointer"}}>
                                    Партнеры
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className="footer-list-item">
                        <h3 className="item-title">
                            Наши продукты
                        </h3>
                        <ul className="item-list flex list-reset">
                            <li className="item-points">
                                <Link to="/omnichannel">
                                    Омниканальные маркетинговые проекты                                
                                </Link>
                            </li>
                            <li className="item-points">
                                <Link to="/crm">
                                    CRM-система Oragen
                                </Link>
                            </li>
                            <li className="item-points">
                                <Link to="/production">
                                    Продакшн
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="footer-list-item">
                        <h3 className="item-title">
                            Связаться с нами
                        </h3>
                        <ul className="item-list flex list-reset">
                            <li className="item-points">
                                Ведущий менеджер по развитию бизнеса<br/>
                                <a href="tel:+79995006454">
                                    +7 929 662 35 61
                                </a>
                            </li>
                            <li className="item-points">
                                Руководитель ИТ-отдела<br/>
                                <a href="tel:+79998699276">
                                    +7 999 869-92-76  
                                </a>
                            </li>
                            <li className="item-points">
                                <a href="mailto:manager@medguru.studio">
                                    manager@medguru.studio
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>

                <ul className="footer-info-list list-reset flex">
                    <li className="info-list-item">
                        ©2025 ООО «МедГуру» 
                    </li>
                    <li className="info-list-item">
                        ИНН 7716860313
                    </li>
                    <li className="info-list-item">
                        КПП 773401001
                    </li>
                    <li className="info-list-item" style={{cursor: "pointer"}}>
                        ОГРН 1177746556130
                    </li>
                </ul>
                <ul className="footer-info-list-add list-reset flex">
                    <li className="info-list-item" style={{cursor: "pointer"}}>
                        Адрес: 123060, г. Москва, ул. Маршала Рыбалко, <br/>дом 2, корпус 6, помещение I, комната 5, офис 722
                    </li>

                    <li className="info-list-item" style={{cursor: "pointer"}}>
                        +7 495 445-45-10 <br/>
                        mail@medguru.studio
                    </li>
                </ul>

                <ul className="footer-info-list-add list-reset flex" style={{justifyContent: 'flex-start', paddingBottom: "63px"}}>
                    <li className="info-list-item" style={{width: "29vw", marginRight: "40px"}}>
                        Основной код ОКВЭД: 62.02 (деятельность консультативная и работы в области компьютерных технологий)
                    </li>

                    <li className="info-list-item" style={{width: "29vw"}}>
                        Виды ИТ-деятельности согласно приказу Минцифры № 449 от 11.05.2023 г.: 1.01, 2.01, 16.01
                    </li>
                </ul>
            </div>
        </section>
  )
}
